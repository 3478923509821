<template>
  <div class="list-balance">
    <div class="header">
      <form-input-search v-model:value="searchValue" placeholder="Search" />
    </div>

    <list-simple
      v-if="balanceCurrencies.length"
      v-model:value="currentCryptoCurrencyId"
      :searchValue="searchValue"
      :list="balanceCurrencies"
      @selected="selectCurrency"
    />
    <div v-else class="item-empty">No result</div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  const { iconUrl } = useDynamicIcon('currency');

  defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
  });

  const walletStore = useWalletStore();
  const { accounts, activeAccount } = storeToRefs(walletStore);
  const { formatBalance } = useProjectMethods();

  const { switchAccount } = useWalletStore();

  const emit = defineEmits(['close', 'changeActiveAccount']);

  const searchValue = ref<string>('');
  const currentCryptoCurrencyId = ref(activeAccount.value?.id);

  const balanceCurrencies = computed(() => {
    return accounts.value.map((item) => {
      const balanceFormat = formatBalance(item.currency, item.balance);
      return {
        ...item,
        name: balanceFormat.currency,
        icon: `${iconUrl.value}/${item.currency}.svg`,
        amount: {
          currenValue:  balanceFormat.amount,
          baseValue: item.baseCurrencyBalance.toFixed(2)
        }
      }
    })
  });

  const selectCurrency = async (id: string): Promise<void> => {
    if (activeAccount.value?.id === id) return;

    const findAccount = accounts.value.find((account) => account.id === id);

    if (findAccount) {
      await switchAccount(findAccount.id);
      emit('changeActiveAccount', findAccount.currency);
    }

    emit('close');
  };
</script>

<style src="~/assets/styles/components/list/balance.scss" lang="scss" />
